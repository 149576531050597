import React, { useEffect } from "react";
import { Row, Col } from "reactstrap";
import Card from "../../components/Card/Card";
import Layout from "../../components/Layout";
import SideNav from "../../components/SideNav/SideNav";
import {
  getPresentations,
  getPresentationDetails,
} from "../../reducers/Presentations/actions";
import Pagination from "../../components/Common/Pagination";
import { connect } from "react-redux";
import { graphql } from "gatsby";
import { urlDescription } from '../../utils/pageDescription'
import "./styles.scss";

const Presentations = ({
  presentations,
  page,
  totalPages,
  getPresentations,
  getPresentationDetails,
  data
}) => {

  useEffect(() => {
    getPresentations({ page });
  }, [getPresentations, page]);

  const handlePageClick = () => {
    getPresentations({ page: page + 1 });
  }

  const staticPresentations = data && data.allStrapiPresentations.edges;

  let url = "";

  if (typeof window !== "undefined") {
    url = new URL(window.location.href);
  }

  return (
    <Layout description={urlDescription[url.pathname]}>
      <div className="building-library-title">
        <h1 className="h2 font-weight-bold">Полезна информация</h1>
      </div>
      <div className="news-wrapper">
        <div className="news-page-card-container">
          <div className="news-page-title">
            <h2 className="h3 font-weight-bold">Презентации</h2>
          </div>

          <Row className="news-page-card-row">
            <Col className='mb-5 sidenav-menu-col' xs={12} md={4} lg={4}>
              <SideNav presentations active="presentations" />
            </Col>
            <Col className='news-page-card-column' lg={8} md={8}>
              {presentations && staticPresentations &&
                staticPresentations.map((p, i) => {
                  return (
                    <Row className="mb-5" key={p.id}>
                      {/* <Card
                        btnText="свали презентацията"
                        cardClass="new-horizontal-card"
                        imageClass="new-horizontal-card-img"
                        cardBodyClass="news-page-horizontal-card-body"
                        btnClass="presentations-page-horizontal-card-btn"
                        url="/presentations"
                        title={p.title}
                        desc={p.description}
                        image={`${process.env.GATSBY_XELLA_BACKEND}${p.image}`}
                        onClick={() => {
                          getPresentationDetails({ id: p.id });
                        }}
                      /> */}
                      <Card
                        id={p.node.id}
                        socialMediaImage={presentations[i]}
                        btnText="свали презентацията"
                        cardClass="new-horizontal-card"
                        imageClass="new-horizontal-card-img"
                        cardBodyClass="news-page-horizontal-card-body"
                        btnClass="presentations-page-horizontal-card-btn mt-xs-4"
                        url="/presentations"
                        title={p.node && p.node.title}
                        desc={p.node && p.node.description}
                        isGatsbyImage={true}
                        image={p.node && p.node.image.localFile && p.node.image.localFile.childImageSharp}
                        onClick={() => {
                          getPresentationDetails({ id: p.node.id });
                        }}
                      />
                    </Row>
                  );
                })}
            </Col>
          </Row>
          <Row className="w-100 justify-content-center">
            <div>
              {
                totalPages && totalPages > 1 
                ?<Pagination
                    handlePageClick={handlePageClick}
                    pageCount={totalPages}
                    currentPage={page}
                  />
                : null
              }
            </div>
          </Row>
        </div>
      </div>
    </Layout>
  );
};

const mapStateToProps = (state) => {
  return {
    presentations: state.presentations.data,
    page: state.presentations.current_page,
    totalPages: state.presentations.total_pages
  };
};

const mapDispatchToProps = {
  getPresentations,
  getPresentationDetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Presentations);

export const query = graphql`
  {
    allStrapiPresentations {
      edges {
        node {
          id: strapiId
          description
          title
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: AUTO)
              }
            }
          }
        }
      }
    }
  }
`

